.modal {
    position: fixed;
    background: #00000075;
    width: 100%;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-box {
    background: #fff;
    width: 380px;
    border-radius: 6px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    background: #EFEFEF;
    border-radius: 6px 6px 0 0;
    padding: 10px 10px;
}
.modal-header h3 {
    margin: 0;
    color:#505050;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
}
.modal-footer {
    background: #EFEFEF;
    border-radius: 0 0 6px 6px;
    padding: 15px 0;
}
.modal-header div {
    background: #f00;
    border-radius: 60px;
    padding: 0px 8px;
    color: #fff;
    width: 4px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-header div span{
    margin-top: -5px;
}

.modal-body {
    padding: 22px 10px;
}
.modal-body p {
    text-align: left;
    margin:0;
    font-size: 14px;
}
.modal-box-form {
    width: 800px;
}

.error {
    color: red;
    text-align: left;
}