.sub-header {
    background:#38C1BA;
}
.sub-header p{
    margin: 0;
    padding: 6px 0;
    color: #fff;
    font-size: 20px;
}
.sub-header p span{
    font-size: 16px;
}
