.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col-md-1 {
  width: 8.333333333333332%;
}
.col-md-2 {
  width: 16.666666666666664%;
}
.col-md-3 {
  width: 25%;
}
.col-md-4 {
  width: 33.33333333333333%;
}
.col-md-5 {
  width: 41.66666666666667%;
}
.col-md-6 {
  width: 50%;
}
.col-md-7 {
  width: 58.333333333333336%;
}
.col-md-8 {
  width: 66.66666666666666%;
}
.col-md-9 {
  width: 75%;
}
.col-md-10 {
  width: 83.33333333333334%;
}
.col-md-11 {
  width: 91.66666666666666%;
}
.col-md-12 {
  width: 100%;
}
.col-sm-12 {
  width: 100%;
}

.content {
  /* display: grid;
    grid-template-areas:
    'header header header header header header header header header header header header'
    'menu main main main main main main main main main main main';
  grid-gap: 10px; */
}
.d-flex {
  display:flex
}
.container {
  padding: 20px;
  /* grid-area: main; */
  width: 100%;
}
.w-100{
  width: 100%;
}
.text-center{
  text-align: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.flex-direction-row {
  flex-direction: row!important;
}
.box-default{
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}
.row {
  display: flex;
  justify-content: flex-start;
  margin:10px 0;
}
.form-control label{
  font-size: 14px;
  margin-bottom: 5px;
  /* text-transform: capitalize; */
}
.form-control {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  margin: 10px 0;
}
.form-control input{
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  padding: 6px;
}
.form-control select{
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  padding: 6px;
}
.form-control textarea{
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  height:100px;
  resize:none;
}
.btn-lateral {
  display: flex;
  margin: 33px 0px 12px 4px;
  padding: 0 8px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  border:none;
  min-width: 100px;
  justify-content: space-between;
}
.btn-lateral p{
  margin: 0;
  padding-right: 5px;
}
.btn-lateral span {
  border-left: 1px solid #000;
  padding: 4px 0;
  padding-left: 10px;
  margin: 2px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}
.btn-lateral span img {
  width:15px;
}
.btn-action {
  display: flex;
  padding: 0 8px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  border:none;
  min-width: 160px;
  justify-content: space-between;
}
.btn-action p{
  margin: 0;
  padding-right: 5px;
}
.btn-action span {
  border-left: 1px solid #000;
  padding: 4px 0;
  padding-left: 10px;
  margin: 2px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}
.btn-action span img {
  width:24px;
}
.btn-cancel {
  border: 1px solid #5CAC21;
  color: #5CAC21;
}
.btn-confirm {
  border: 1px solid #5CAC21;
  background-color: #5CAC21;
  color: #fff;
}
.btn-confirm span {
  border-color:  #fff;
}
.btn-cancel span {
  border-color:  #5CAC21;
}
.align-items-center {
  align-items: center;
}
.mt-15 {
  margin-top: 15px;
}
.position-relative {
  position: relative;
}
.hidden {
  visibility:hidden;
}
.cancel {
  text-decoration: line-through;
}