.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
}
.toggle .checkbox-toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-toggle:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

.checkbox-toggle:checked + .slider-toggle {
  background-color: #38C1BA;

}

.checkbox-toggle:focus + .slider-toggle {
  box-shadow: 0 0 1px #38C1BA;
}

.checkbox-toggle:checked + .slider-toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded slider-toggles */
.slider-toggle.round {
  border-radius: 34px;
}

.slider-toggle.round:before {
  border-radius: 50%;
}
