.list-pagination {
    display: flex;
    list-style-position: inside;
    padding: 0;
}
.list-pagination li{
    list-style: none;
    cursor: pointer;
    margin-right: 5px;
}
.list-pagination li.active {
    background: #38C1BA;
    color:#fff;
    padding: 0px 5px;
}
.list-pagination li button{
    background: transparent;
    border:none;
    cursor: pointer;
}