.title-page {
    border-bottom: 2px solid #D0D0D0;
    padding-bottom: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.title-page h3{
    font-size: 26px;
    font-weight: 400;
    color: #505050;
    margin: 0;
    margin-left: 10px;
}
.icon-title {
    width: 40px;
}
.border-icon {
    border: 3px #38c1ba solid;
    border-radius: 26px;
}

.title-secundary{
    display: flex;
    align-items: center;
}
.title-secundary h2{
    color:#38C1BA;
    font-size: 22px;
    font-weight: 400;
    margin:0;
}
.title-secundary .box-info {
    background: #fff;
    box-shadow: #00000033 0 6px 20px;
    width: 240px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    display:none;
}

.title-secundary .box-info p{
    margin: 0;
    font-size: 12px;
    padding: 0 10px;
    color:#333;
}
.i-icon {
    position: relative;
    margin-left: 10px;
    border: 2px solid #C0C0C0;
    padding: 0px 7px;
    border-radius: 60px;
    font-size: 12px;
    color: #C0C0C0;
    width: 6px;
    font-size: 15px;
    justify-content: center;
}
.box-info {
    display: none;
    position: absolute;
    top: -3px;
    left: -3px;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px;
    min-width: 160px;
    min-height: 50px;
    background: #fff;
}
.box-info span {
    background: #ccc;
    padding: 1px 6px;
    font-size: 12px;
    color: #fff;
    border-radius: 60px;
}
.i-icon:hover .box-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}