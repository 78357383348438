.side-menu {
    width:5%;
}
.side-menu ul {
    background: #000;
    padding: 6px 0;
    border-radius: 0 12px 12px 0;
    position: absolute;
    margin-top: 50px;
    left: 0;
}
.side-menu ul li{
    border-radius: 60px;
    display: flex;
    align-items: center;
    padding: 5px;
    flex-direction: row;
}
.side-menu ul li a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    text-decoration: none;
}
.side-menu ul li a img{
    width: 40px;
    border-radius: 60px;
    padding: 5px;
}
.side-menu ul li a p{
    margin: 0 7px 0 5px;
    display: none;
}
.side-menu ul li:hover a img{
    background: #38c1ba
}
.side-menu ul li a.active img{
    background: #38c1ba
}

.side-menu:hover p {
    display: block;
}