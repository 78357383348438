.table-default {
  width: 100%;
  margin-top: 5px;
  border-collapse: collapse;
}
.table-default thead span{
  background: #D0D0D0;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}
.table-default thead tr th{
  text-align: left;  
}
.table-default tbody{
  text-align: left;
  font-weight: 400;
}
.input-search {
  height: 20px;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 6px;
}
.table-default tbody tr td{
  padding:5px;
  border-bottom: 1px solid #ccc;
}
.table-default tbody tr{
  cursor: pointer;
}
.table-default tbody tr:hover{
  background-color: #f0f0f0;
}
.select-table {
  border-radius: 6px;
  width: 50px;
  border: 1px solid #ccc;
  height: 25px;
  margin-right: 10px;
} 
.list-icons {
  padding: 0;
  margin: 0;
  display: flex;
  position: absolute;
  top:6px;
}
.list-icons li {
  list-style: none;
  margin: 0 75px;
}
.icon-delete {  
  background-size: 15px;
  width: 24px;  
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}
.table-default tbody tr:hover td:last-of-type ul li.icon-delete {
  background-image: url('../../assets/images/icons/icon-excluir.svg');
  background-color: #38c1ba;
}

.icon-justify {  
  background-size: 15px;
  width: 24px;  
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}
.table-default tbody tr:hover td:last-of-type ul li.icon-justify {
  background-image: url('../../assets/images/icons/icon-justify.svg');
  background-color: #38c1ba;
}


.table-modal {
  width:100%;
  border-collapse: collapse;
}
.table-modal thead {
  display: block;
  border-radius: 4px 4px 0 0;
  border: 1px solid #D0D0D0;
}
.table-modal thead tr{
  background: #eee;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table-modal thead tr td{
  padding:6px;
  width: 100%;
}
.table-modal tbody {
  overflow-y: scroll;
  height: 200px;
  display: block;
  border-radius: 0 0 4px 4px;
  border: 1px solid #D0D0D0;
  border-top: 0;
}
.table-modal tbody tr{
  border-radius:  0 0 4px 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table-modal tbody tr td{
  padding: 6px;
  width: 100%;
}