.header {
    background-image: url('../../assets/images/fundo-header.png');
    color: #fff;
    justify-content: space-between;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.logo_cobotics{
    position: absolute;
    top: 5px;
    left: 50px;
    width: 130px;
}
.area-header {
    grid-area: header;
}
.title-header{
    font-size: 25px;
    margin: 0;
    text-transform: uppercase;
}