.btn-emitir {
    background: #38C1BA;
    border: none;
    color: #fff;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    float: right;
    margin:0 10px;
    text-decoration: none;
}
.btn-emitir svg {
    margin-right: 5px;
}
